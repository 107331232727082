<template>
  <div class="pc-page-content">
    <div class="pc-menu-operation-panel operation-panel permission">
      <el-button type="primary" @click="refresh('filterFrom')">刷新</el-button>
      <el-button type="primary" @click="goBack">返回</el-button>
    </div>
    <div class="pc-menu-tree pc-tree">
      <el-tree
        class="filter-tree"
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        :default-expanded-keys="defaultExpandedKeys"
        :highlight-current="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
        ref="elTree"
      >
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <span>{{ node.label }}</span>
            <span v-if="data.menuCode === 'position'">
              <el-button
                type="text"
                size="mini"
                @click="loadPage('BindMenu', data)"
                v-permission="['uac:permission:BindMenu']"
                >分配菜单</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="loadPage('BindAction', data)"
                >分配权限</el-button
              >
            </span>
          </span>
        </template>
      </el-tree>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filterText: '',
      treeData: [],
      methodType: '',
      currentId: '',
      editMenuFormData: {},
      defaultExpandedKeys: [],
      defaultProps: {
        children: 'subMenu',
        label: 'menuName',
      },
    };
  },
  components: {},
  watch: {
    filterText(val) {
      this.$refs.elTree.filter(val);
    },
  },
  created() {
    this.initMenuTree();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadPage(name, params) {
      this.$router.push({
        name,
        params,
      });
    },
    initMenuTree() {
      this.ajax({
        url: '/uac/department/getTree/',
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.treeData = data;
          this.defaultExpandedKeys.push(this.treeData[0].id);
        },
      });
    },
    refresh() {
      this.initMenuTree();
    },
    handleNodeClick(data) {
      if (!data.id) {
        return;
      }
      this.currentId = data.id;
    },
    filterNode(value, data) {
      if (!value) return true;
      console.info(value, data);
      return data.menuName.indexOf(value) !== -1;
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.pc-menu-operation-panel {
  margin-bottom: 20px;
}
.pc-menu-tree {
  display: inline-block;
}
.pc-page-content .pc-menu-tree .el-tree-node__content {
  padding-right: 20px;
}
.pc-menu-edit-panel {
  display: inline-block;
  vertical-align: top;
}
.pc-menu-header {
  margin: 0 0 20px 20px;
}
.pc-menu-operation-panel.permission {
  button:first-child {
    border-radius: 20px 0 0 20px !important;
  }
  button:last-child {
    border-radius: 0 20px 20px 0 !important;
  }
}
</style>
